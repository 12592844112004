import React from 'react'
import './Home.css'
import Robot from "../../img/Robot.png"
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

import NavBar from "../../components/NavBar/NavBar"
import AboutUsSection from "../../components/AboutUsSection/AboutUsSection"
import Specification from '../../components/Specification/Specification'
import EventList from '../../components/events/EventList'
import Sponsors from '../../components/Sponsors/Sponsors'
import Timeline from '../../components/Timeline/Timeline'
import Contact from '../../components/Contact/Contact'
import Footer from '../../components/Footer/Footer'



export default function Home() {
//   const [showButton, setShowButton] = useState(false);
//   const [showEvent, setShowEvent] = useState(true);

//   useEffect(() => {
//     const trackWindowWidth = () => {
//       setShowButton(window.innerWidth <= 768);
//     };

//     trackWindowWidth();
//     window.addEventListener('resize', trackWindowWidth);
//     return () => {
//       window.removeEventListener('resize', trackWindowWidth);
//     };
//   }, []);

//   const showEvents = () => {
//     setShowEvent(false);
//   };

    return (
        <div className="bg scrollbar-hide home">
            <div className="bottomGradient"></div>
            
            <NavBar />

            <section>
                <div className="content">
                    <img className="logo" src={Robot} alt="logo" />
                </div>
                <div className="typing-demo" >
                    <h1>Hi, welcome to </h1>
                    <h1> cyber  processing unit</h1>
                </div>
            </section>

            <AboutUsSection />
            <Specification />
            {/* <EventList showEvent={showEvent} showButton={showButton} showEvents={showEvents}/> */}
            <EventList/>
            {/* <Sponsors /> */}
            {/* Trainings */}
            <Timeline />
            <Contact />


            {/***************************FouterSection**********************************/}
            {/* <div className="fouter">
        <div className="row">
          <div className="ContactFouter">ContactUS</div>
          <a href="https://www.facebook.com/search/top?q=cpu-isimm"><img className="facefouter" src={facebook} alt="face" /></a>
          <a href="https://www.instagram.com/cpu_isimm/?hl=fr"><img className="instaFouter" src={insta} alt="insta" /></a>
        </div>
        <h1 className='tag'>© 2023-2024,  CPU</h1>
      </div> */}
            <footer className="footer">
                <div className="footer__content">
                    {/* <div className="footer__section">
                        <h2>Get connected with us on social networks:</h2>
                        <div className="footer__icons">
                            <FaFacebookF className="footer__icon" />
                            <FaInstagram className="footer__icon" />
                            <FaLinkedinIn className="footer__icon" />
                        </div>
                    </div>
                    <div className="footer__section">
                        <p><FaEnvelope className="footer__icon" /> Email: contact@cpu-isimm.tn</p>
                        <p><FaPhone className="footer__icon" /> Phone: +1234567890</p>
                    </div>
                    <div className="footer__section">
                        <p>&copy; 2024 Cpu Team. All Rights Reserved.</p>
                    </div> */}
                    <Footer />
                </div>
            </footer>

            {/* <Footer/> */}


        </div>
    )
}