import cyber1 from '../../img/cyberbot/cyberbotv1.jpg'
import cyber2 from '../../img/cyberbot/cyberbotv2.jpg'
import cyber3 from '../../img/cyberbot/cyberbotv3.jpg'

export const events = [
    {
        image: cyber3,
        name: "ISIMM-CYBERBOT v3.0",
        date: "April 21, 2024",
        passed : false,
        description: "ISIMM-CYBERBOT v3.0 stands as an international event designed to facilitate the gathering of passionate and competitive individuals, fostering the improvement of their abilities, expanding their knowledge, and optimizing their robots' performance."
    },
    {
        image: cyber2,
        name: "ISIMM-CYBERBOT v2.0",
        date: "February 12, 2023",
        passed : true,
        path:"events/v2_0",
        description: "ISIMM-CYBERBOT v2.0 has transformed into an international gathering, providing an avenue for enthusiastic and competitive individuals to enhance their skills, broaden their knowledge, and optimize the performance of their robots."
    },
    {
        image: cyber1,
        name: "ISIMM-CYBERBOT v1.0",
        date: "March 13, 2022",
        passed : true,
        path:"events/v1_0",
        description: "ISIMM-CYBERBOT v1.0 was an inaugural international event aimed at bringing together passionate and competitive individuals to enhance their skills, knowledge, and the performance of their robots."
    },
    
]