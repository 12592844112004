import React, { useRef, useState } from 'react';
import './Form.css';
import logo from '../../img/cpu-logo.png';
import axios from 'axios'
import Footer from '../../components/Footer/Footer';
import Alert from '../../components/Alert/Alert';

function Form() {
    const formRef = useRef(null);
    const actionUrl = "https://docs.google.com/forms/u/0/d/e/1FAIpQLSfYbQu-cpDC5O8uOB4maMhoz4x-3lt3a4M7iDdl7OM1gc15jQ/formResponse";
    const [loading, setLoading] = useState(false);
    const iframeRef = useRef(null);
    const [memberCount, setMemberCount] = useState("");
    const [formData, setFormData] = useState({
        fname: "",
        email: "",
        phone: "",
        university: "",
        club: "",
        robot: "",
        challenge: "",
        members: "",
        fname_member2: "",
	phone2: "",
        fname_member3: ""
    });
    const [alertMsg, setAlertMsg] = useState("")
    const customAlert = (msg) => {
        setAlertMsg(msg);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const createIframe = (ifameURL) => {
        const newIframe = document.createElement('iframe');
        newIframe.style.width = "0.5px";
        newIframe.style.height = "0.5px";
        newIframe.src = ifameURL;
        newIframe.onload = handleIframeLoad;

        // Append the new iframe to the DOM
        if (iframeRef.current) {
            iframeRef.current.appendChild(newIframe);
        }
    };

    const handleIframeLoad = () => {
        console.log("Iframe loaded");
        iframeRef.current.removeChild(iframeRef.current.children[0]);
        setLoading(false);
    };

    // const handleSubmit = (e) => {
    //     // e.preventDefault();
    //     // if (loading) return;
    //     // const formDataAsUrlParams = new URLSearchParams(formData).toString();
    //     // console.log(formDataAsUrlParams);
    //     // setLoading(true);

    //     // createIframe(`${actionUrl}?${formDataAsUrlParams}`);

    // };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) return;

        try {
            setLoading(true);
            const response = await axios.post('https://server.cpu-isimm.tn/api/form/', formData);

            // Check if the response contains a message
            if (response.data && response.data.message) {
                customAlert(response.data.message);
            } else {
                customAlert('An unknown error occurred');
            }
            setFormData({
                fname: "",
                email: "",
                phone: "",
                university: "",
                club: "",
                robot: "",
                challenge: "",
                members: "",
                fname_member2: "",
		phone2: "",
                fname_member3: ""
            })
        } catch (error) {
            // Check if the error response contains a message
            if (error.response && error.response.data && error.response.data.message) {
                customAlert(error.response.data.message);
            } else {
                customAlert('An unknown error occurred');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='all Form'>
            {alertMsg && <Alert msg={alertMsg} handleOk={()=>setAlertMsg("")} />}
            <img className="logo" src={logo} alt="logo" />
            <section className="container">
                <header>
                    Registration Form
                </header>

                <form onSubmit={handleSubmit} className="form" ref={formRef}>
                    <div className="input-box">
                        <div className='flex'><label>Full Name</label><div></div></div>
                        <input type="text" name="fname" pattern="[a-zA-Z0-9\s]{1,60}" placeholder="Enter full name" value={formData.fname} onChange={handleChange} required />
                    </div>
                    <div className="input-box">
                        <div className='flex'><label>Email Address</label><div></div></div>
                        <input type="email" name="email" pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" placeholder="Enter email address" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className="input-box">
                        <div className='flex'><label>Phone Number</label><div></div></div>
                        <input type="tel" name="phone" pattern="\d{8,15}" placeholder="Enter your phone number" value={formData.phone} onChange={handleChange} required />
                    </div>
                    <div className="input-box">
                        <div className='flex'><label>University</label><div></div></div>
                        <input type="text" name="university" pattern="[a-zA-Z0-9\s]{1,60}" placeholder="Enter your university" value={formData.university} onChange={handleChange} required />
                    </div>
                    <div className="input-box">
                        <div className='flex'><label>Club</label><div></div></div>
                        <input type="text" name="club" pattern="[a-zA-Z0-9\s]{1,60}" placeholder="Enter your club name" value={formData.club} onChange={handleChange} required />
                    </div>
                    <div className="input-box">
                        <div className='flex'><label>Robot Name</label><div></div></div>
                        <input type="text" name="robot" pattern="[a-zA-Z0-9\s]{1,60}" placeholder="Enter the robot name" value={formData.robot} onChange={handleChange} required />
                    </div>
                    <div className="input-box">
                        <div className='flex'><label>Challenge</label><div></div></div>
                        <select name="challenge" value={formData.challenge} onChange={handleChange} required>
                            <option value="">Select the Challenge</option>
                            <option value="fighter">Fighter</option>
                            <option value="allterrain">All Terrain</option>
                            <option value="autonomous">Autonomous</option>
                            <option value="junior">Junior</option>
                        </select>
                    </div>
                    <div className="input-box">
                        <div className='flex'><label>Team members</label><div></div></div>
                        <select name="members" value={formData.members} onChange={(e)=>{handleChange(e);setMemberCount(e.target.value)}} required>
                            <option value="">Select the number of members</option>
                            <option value="1">1 member</option>
                            <option value="2">2 members</option>
                            <option value="3">3 members</option>
                        </select>
                    </div>
                    <div className="input-box">
                        <div className='flex'><label>Second member</label><div></div></div>
                        <input type="text" name="fname_member2" pattern="[a-zA-Z0-9\s]{1,60}" placeholder="Enter full name of the second team member" value={formData.fname_member2} onChange={handleChange} disabled={!(memberCount === "" || parseInt(memberCount) > 1)} required={(memberCount === "" || parseInt(memberCount) > 1) ? true : false} />
                    </div>
		    <div className="input-box">
                        <div className='flex'><label>Second Phone Number</label><div></div></div>
                        <input type="tel" name="phone2" pattern="\d{8,15}" placeholder="Enter your phone number" value={formData.phone2} onChange={handleChange} disabled={!(memberCount === "" || parseInt(memberCount) > 1)} required={(memberCount === "" || parseInt(memberCount) > 1) ? true : false} />
                    </div>
                    <div className="input-box">
                        <div className='flex'><label>Third member</label><div></div></div>
                        <input type="text" name="fname_member3" pattern="[a-zA-Z0-9\s]{1,60}" placeholder="Enter full name of the third team member" value={formData.fname_member3} onChange={handleChange} disabled={!(memberCount === "" || parseInt(memberCount) > 2)} required={(memberCount === "" || parseInt(memberCount) > 2) ? true : false} />
                    </div>

                    <button type="submit">Submit</button>
                </form>
            </section>
            <div ref={iframeRef}></div>
            <Footer/>
        </div>
    )
}

export default Form;
