import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route} from "react-router-dom";
import './App.css';
import Home from "./pages/Home/Home"
import Form from "./pages/Form/Form"
import EventPage from "./components/events/EventPage.js";
import EventPagev2 from "./components/events/EventPagev2.js";
// import NoPage from "./pages/NoPage"

function App() {
  return (
    <HashRouter>
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/events/v1_0" element={<EventPage />} />
        <Route path="/events/v2_0" element={<EventPagev2 />} />

        <Route path="/form" element={<Form />} />
        {/* <Route path="*" element={<NoPage />} />  */}
        {/* <Route path="*" element={<NoPage />} />  */}
      </Routes>
    </HashRouter>
  );
}

export default App;