import React from 'react'
import './Footer.css'
import logo from '../../img/cpu-logo.png';
import { IonIcon } from '@ionic/react';
import '@fortawesome/fontawesome-free/css/all.css';
import { logoFacebook, logoLinkedin, logoInstagram } from 'ionicons/icons';
import { useNavigate } from 'react-router-dom';
export default function Footer() {
    const navigate = useNavigate()
    const goToHome = () => {
        navigate('/')
        setTimeout(() => {
          document.scrollingElement.scrollTop = 0;
        }, 0);
    }
    const goToAbout = () => {
        navigate('/');
        setTimeout(() => {
          const element = document.getElementById('aboutUs');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0); // Timeout to ensure the page has fully rendered before scrolling
      };
    const goToEvents = () => {
        navigate('/');
        setTimeout(() => {
          const element = document.getElementById('events');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0); // Timeout to ensure the page has fully rendered before scrolling
      };
      const goToTraining = () => {
        navigate('/');
        setTimeout(() => {
          const element = document.getElementById('trainings');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0); // Timeout to ensure the page has fully rendered before scrolling
      };
      const goToContact = () => {
        navigate('/');
        setTimeout(() => {
          const element = document.getElementById('contact');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0); // Timeout to ensure the page has fully rendered before scrolling
      };
      const goToForm = () => {
        navigate('/form')
      }
    return (
        <div>
            <footer className="footer-distributed">
                <div className="footer-left">
                    <img className="logoo" src={logo} alt="logo" />
                    <p className="footer-links">
                        <a onClick={goToHome} className="link-1">Home</a>
                        <a onClick={goToForm}>Registration</a>
                        <a onClick={goToAbout}>AboutUs</a>
                        <a onClick={goToEvents}>Events</a>
                        <a onClick={goToTraining}>Trainings</a>
                        <a onClick={goToContact}>Contact us</a>
                    </p>
                </div>
                <div className="footer-center">
                    <div className="location">
                        <i className="fa fa-map-marker"></i>
                        <p><span>BP 223 </span> Av. de la Corniche, Monastir 5000</p>
                    </div>
                    <div className="space"></div>
                    <div className="phone">
                        <i className="fa fa-phone"></i>
                        <div className="name">
                            <p className="phone-contact">
                              <span className="role">President: </span>
                              <span className="owner">Rayen Lahmer</span>
                              <span className="number">+216 50 354 468</span>
                            </p>
                            <p className="phone-contact">
                              <span className="role">Vice: </span>
                              <span className="owner">Maram Belgacem</span>
                              <span className="number">+216 24 577 774</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="footer-right">
                    <p className="footer-company-about">
                        <span>Social contact</span>
                        if you want to explore more and know about our club and be updated by the latest activities and trainings please follow our social media accounts
                    </p>
                    <div className="footer-icons">
                        <a href="https://www.facebook.com/CPU.ISIMM"> <IonIcon icon={logoFacebook} /></a>
                        <a href="https://www.linkedin.com/in/cpu-isimm-068816218/"><IonIcon icon={logoLinkedin} /></a>
                        <a href="https://www.instagram.com/cpu.isimm">  <IonIcon icon={logoInstagram} /></a>
                    </div>
                </div>
                <div className="reserved_rights">&copy; 2024 Cpu Team. All Rights Reserved.</div>
            </footer>
        </div>
    )
}