import React from 'react'
import Cell from "./Cell/Cell"

import "./Timeline.css"

export default function Timeline() {
    return (
        <>
            <div id="trainings" className="trainings">
                <h1 className='titlevent'>Our Trainings</h1>
                <Cell title="React Js" date="27-28 Jan 2024" atRight={false} />
                <Cell title="JavaScript" date="3 Dec 2023" atRight={false} />
                <Cell title="Git | Github" date="25 Nov 2023" atRight={false} />
                <Cell title="LineFollower Workshop" date="18 Nov 2023" atRight={true} />
                <Cell title="Soft Step" date="21-22 Oct 2023" atRight={true} />
                <Cell title="HTML | CSS" date="14 Oct 2023" atRight={false} />
                <Cell title="Hard Step" date="7-8 Oct 2023" atRight={true} />
                <div className="moreBtnRow">
                    <span className="moreBtn" onClick={(e)=>{
                        e.target.parentNode.parentNode.classList.add("expanded");
                        e.target.parentNode.parentNode.removeChild(e.target.parentNode);
                    }}>Read More</span>
                </div>
            </div>
        </>
    )
}
