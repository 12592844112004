import React, { useRef } from 'react';
import './styles/SpecificationItem.css';
import coming from '../../img/specification/coming.png';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const SpecificationItem = ({ item }) => {
    const { ref, inView } = useInView({ threshold: 0 });
    const refContent = useRef(null);

    const variants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
            },
        },
    };

    return (
        <motion.div
            ref={ref}
            className={(item.data.available)?'SpecificationItem':'SpecificationItem cls'}
            variants={variants}
            initial='hidden'
            animate={inView ? 'visible' : 'hidden'}
        >
            <div className='SpecificationItem_header'>
                <motion.img
                    src={item.data.cover}
                    alt='book cover'
                    loading='lazy'
                    ref={refContent}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5 }}
                />
            </div>
            <div className='SpecificationItem_content'>
                <button><a href={item.data.docurl}>Read specification</a></button>
            </div>
            {item.data.available ? (
                null
            ) : (
                <div className='notAval'>
                    <img src={coming} alt='coming soon' loading='lazy' />
                </div>
            )}
        </motion.div>
    );
};

export default SpecificationItem;
