import React, { useState, useEffect } from 'react'
import logo from '../../img/cpu-logo.png';

import "./NavBar.css"
import { useNavigate } from 'react-router-dom';

export default function NavBar() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 80;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const navigate = useNavigate()
    const goToAbout = () => {
        navigate('/');
        setTimeout(() => {
          const element = document.getElementById('aboutUs');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0); // Timeout to ensure the page has fully rendered before scrolling
      };
      const goToSpecification = () => {
        navigate('/');
        setTimeout(() => {
          const element = document.getElementById('specifications');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0); // Timeout to ensure the page has fully rendered before scrolling
      };
    const goToEvents = () => {
        navigate('/');
        setTimeout(() => {
          const element = document.getElementById('events');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0); // Timeout to ensure the page has fully rendered before scrolling
      };
      const goToTraining = () => {
        navigate('/');
        setTimeout(() => {
          const element = document.getElementById('trainings');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0); // Timeout to ensure the page has fully rendered before scrolling
      };
      const goToContact = () => {
        navigate('/');
        setTimeout(() => {
          const element = document.getElementById('contact');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0); // Timeout to ensure the page has fully rendered before scrolling
      };
      const goToForm = () => {
        navigate('/form')
      }
    return (
        <div className="header">
            <header className="navBar light sticky top-0 z-10">
                <img className="logo" src={logo} alt="logo" />
                <a className="font-bold hover:text-CPU text-xl" onClick={goToAbout}>About us</a>
                <a className="font-bold hover:text-CPU text-xl" onClick={goToForm}>Registration</a>
                <a className="font-bold hover:text-CPU text-xl" onClick={goToSpecification}>Specification</a>
                <a className="font-bold hover:text-CPU text-xl" onClick={goToEvents}>Events</a>
                <a className="font-bold  hover:text-CPU text-xl" onClick={goToTraining}>Trainings</a>
                {/* <a className="font-bold hover:text-CPU text-xl" onClick={goToContact}>Contact us</a> */}
            </header>
            <div id="navBarWrapper" className={scrolled ? "m-navBar-wrapper" : "m-navBar-wrapper transparent"} data-expanded="false">
                <div className="m-navBar">
                    <div className="top">
                        <a href="#"><img src={logo} alt="logo" className="logo" /></a>
                        <button onClick={() => {
                            var e = document.getElementById('navBarWrapper');
                            e.setAttribute('data-expanded', e.getAttribute('data-expanded') == 'false');
                        }
                        }>
                            <svg viewBox="0 0 10 10" width="100%" height="100%">
                                <path d="M2,2 L8,2 L8,5 L6,5 L2,5 L2,8 L8,8 L2,2 L8,2 L2,8" fill="none" stroke="black" />
                            </svg>
                        </button>
                    </div>
                    <div className="middle">
                        <a className="selected" onClick={goToAbout}>About us</a>
                        <a onClick={goToForm}>Registration</a>
                        <a onClick={goToSpecification}>Specification</a>
                        <a onClick={goToEvents}>Events</a>
                        <a onClick={goToTraining}>Trainings</a>
                        {/* <a onClick={goToContact}>Contact us</a> */}
                    </div>
                    <span className="highlighter"></span>
                </div>
            </div>
        </div>
    )
}